import { useState } from "react";
import "./App.css";

function PaymentForm() {
  const options = {
    SEK: "STS Alpresor CC SEK",
    NOK: "STS Alpereiser CC NOK",
    EUR: "STS Alppimatkat CC EUR",
  };

  const host = "https://alpresor-moto.web.app";

  const [terminal, setTerminal] = useState({});
  const [bookingNr, setBookingNr] = useState();
  const [amount, setAmount] = useState();
  const [showPaymentLink, setShowPaymentLink] = useState(false);

  const selectTerminal = (term) => {
    const t = { terminal: options[term], currency: term };
    setTerminal(t);
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <h1>Alpresor payments</h1>
      <div>
        <select onChange={(e) => selectTerminal(e.target.value)}>
          <option>Select currency</option>
          <option value="EUR">EUR</option>
          <option value="SEK">SEK</option>
          <option value="NOK">NOK</option>
        </select>
      </div>
      <div>
        <label htmlFor="booking_nr">Booking number:</label>
        <input
          type="text"
          name="booking_nr"
          onChange={(e) => setBookingNr(e.target.value)}
        ></input>
      </div>
      <div>
        <label htmlFor="amount">Amount:</label>
        <input
          type="number"
          name="amount"
          onChange={(e) => setAmount(e.target.value)}
        ></input>
      </div>
      <div>
        <button onClick={() => setShowPaymentLink(!showPaymentLink)}>
          {!showPaymentLink ? "Show payment link" : "Hide payment link"}
        </button>
      </div>
      <div>
        {showPaymentLink && (
          <div>
            <p>
              <a
                href={`${host}/getPaymentLink?amount=${amount}&terminal=${terminal.terminal}&shop_orderid=${bookingNr}&currency=${terminal.currency}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${host}/getPaymentLink?amount=${amount}&terminal=${terminal.terminal}&shop_orderid=${bookingNr}&currency=${terminal.currency}`}
              </a>
            </p>
            <button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${host}/getPaymentLink?amount=${amount}&terminal=${terminal.terminal}&shop_orderid=${bookingNr}&currency=${terminal.currency}`
                );
              }}
            >
              Copy link
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentForm;
